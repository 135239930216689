import React, { useEffect, useState } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import ReactTooltip from "react-tooltip";
import { Checkbox, Input } from "expano-components";
import { client } from "utils/api";
import { Modal, Loader } from "components";
import { ReactComponent as WebIcon } from "icons/web.svg";

import { useProductsPrices } from "../../../../../../context/provider";

const tooltip_tip =
  "Sugerowana cena wyliczona na podstawie funkcji przeliczona przez mnożnik";

const roundTo99 = (price) => {
  return Math.floor(price) + 0.99;
};

const roundTo49or99 = (price) => {
  let base = Math.floor(price);
  let decimal = price - base;

  return decimal >= 0.5 ? base + 0.99 : base + 0.49;
};

const calculateCurrencySalesPrice = ({
  currency_price,
  currency,
  multiplier,
}) => {
  const multiplier_price = currency_price * multiplier;

  return currency === "eur"
    ? roundTo49or99(multiplier_price)
    : roundTo99(multiplier_price);
};

const Offers = ({ channel_products, currency, currency_price }) => {
  const [is_open, setOpen] = useState(false);
  const data = channel_products?.filter(
    ({
      channel: {
        platform: { currency_code },
      },
    }) => currency_code.toLowerCase() === currency
  );

  if (!data.length) {
    return null;
  }

  return (
    <div className="m-b-10">
      <button
        type="button"
        className="text-link"
        onClick={() => setOpen(!is_open)}
      >
        <span>Oferty</span>{" "}
        <svg
          height="14"
          width="14"
          viewBox="0 0 20 20"
          style={{ transform: `scaleY(${is_open ? "-1" : "1"})` }}
        >
          <path
            fill="inherit"
            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
          />
        </svg>
      </button>

      {is_open && (
        <div className="grid m-t-10">
          <table class="table product_prices_table">
            <thead class="table__header table-head">
              <tr>
                <th width="180px">Kanał</th>
                <th width="70px">Mnożnik</th>
                <th width="150px">Cena</th>
                <th>Oferty</th>
              </tr>
            </thead>
            <tbody class="table__body table-body">
              {data
                .sort((a, b) => a.channel.id - b.channel.id)
                .map(
                  ({
                    price,
                    sales_price,
                    channel: { id, price_reference_multiplier, identifier },
                    offers,
                  }) => (
                    <tr key={id}>
                      <td>{identifier}</td>
                      <td>{price_reference_multiplier}</td>
                      <td>
                        {currency_price
                          ? (
                              currency_price * price_reference_multiplier
                            ).toFixed(2)
                          : price || "-"}{" "}
                        /{" "}
                        {currency_price
                          ? calculateCurrencySalesPrice({
                              currency_price,
                              currency,
                              multiplier: price_reference_multiplier,
                            })
                          : sales_price || "-"}
                      </td>
                      <td>
                        <div className="offers-group">
                          {offers.map(({ url }) => (
                            <a
                              href={url}
                              key={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <WebIcon />
                            </a>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const EditPricesModal = ({
  purchase_price_netto_value,
  vat_rate,
  purchase_price_brutto_value,
  purchase_price_brutto_value_eur,
  purchase_price_brutto_value_czk,
  purchase_price_brutto_value_sek,
  purchase_price_brutto_value_gbp,
  purchase_price_brutto_value_huf,
  suggested_sale_price_pln,
  suggested_sale_price_eur,
  suggested_sale_price_czk,
  suggested_sale_price_sek,
  suggested_sale_price_gbp,
  suggested_sale_price_huf,
  sales_price_pln,
  sales_price_eur,
  sales_price_czk,
  sales_price_sek,
  sales_price_gbp,
  sales_price_huf,
  product_id,
  product_sku,
  product_name,
  pristine,
  handleSubmit,
  change,
}) => {
  const [is_saving, setSaving] = useState(false);
  const [is_loading, setLoading] = useState(true);
  const [product_data, setProductData] = useState(null);
  const {
    state: { exchange_rates },
    setProductId,
    calculateMarkup,
    calculateMargin,
    updateProduct,
  } = useProductsPrices();

  const handleClose = () => setProductId(null);

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      await updateProduct(product_id, values);
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  useEffect(() => {
    getProductData(product_id);
  }, [product_id]);

  const getProductData = async (product_id) => {
    try {
      const {
        data: { global_image_url, name, sku, channel_products },
      } = await client.get(`/products/${product_id}?context=ui_show`);

      setProductData({ global_image_url, name, sku, channel_products });
      setLoading(false);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd pobierania danych o produkcie.");
      console.log(error);
    }
  };

  const getCurrencyRate = (currency) =>
    exchange_rates.find((item) => item.currency === currency)?.rate || 0;

  const is_disabled =
    !purchase_price_brutto_value || purchase_price_brutto_value <= 0;

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        title: `${product_sku} - ${product_name}`,
      }}
    >
      {is_loading ? (
        <div className="row p-b-20 jc-c">
          <Loader />
        </div>
      ) : (
        <>
          <ReactTooltip
            arrowColor="#ebecf3"
            backgroundColor="#ebecf3"
            textColor="#74788d"
            place="right"
            type="light"
            effect="float"
          />
          <p className="m-b-10">
            {is_disabled ? (
              <strong className="text-danger">Brak ceny zakupu!</strong>
            ) : (
              <>
                <strong className="m-r-20">
                  Zakup (netto): {purchase_price_netto_value || "-"} PLN
                </strong>
                <strong className="m-r-20">
                  Zakup (brutto): {purchase_price_brutto_value || "-"} PLN
                </strong>
                <strong>VAT: {vat_rate}%</strong>
              </>
            )}
          </p>
          <p>
            <strong>PLN</strong>
          </p>
          <div className="row ai-c m-b-15">
            <div className="box-6">
              <Field
                component={Input}
                name="sales_price_pln"
                type="number"
                postfix={[
                  <span
                    onClick={() =>
                      change("sales_price_pln", suggested_sale_price_pln)
                    }
                  >
                    <span className="text-hoverable prices-tooltip-sup-container">
                      {suggested_sale_price_pln}
                      <span
                        className="prices-tooltip-sup"
                        data-tip={tooltip_tip}
                      >
                        i
                      </span>
                    </span>
                  </span>,
                  "PLN",
                ]}
                disabled={is_disabled}
              />
            </div>
            {sales_price_pln > 0 && (
              <div className="box-6">
                <p>
                  narzut:{" "}
                  <strong>
                    {" "}
                    {calculateMarkup({
                      purchase_price: purchase_price_brutto_value,
                      sales_price: sales_price_pln,
                    })}
                    %
                  </strong>
                  , marża:{" "}
                  <strong>
                    {" "}
                    {calculateMargin({
                      purchase_price: purchase_price_brutto_value,
                      sales_price: sales_price_pln,
                    })}
                    %
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Offers
            channel_products={product_data.channel_products}
            currency="pln"
            currency_price={sales_price_pln}
          />
          <p>
            <strong>EUR</strong>
          </p>
          <div className="row ai-c m-b-15">
            <div className="box-6">
              <Field
                component={Input}
                name="sales_price_eur"
                type="number"
                postfix={[
                  <span
                    onClick={() =>
                      change("sales_price_eur", suggested_sale_price_eur)
                    }
                  >
                    <span className="text-hoverable prices-tooltip-sup-container">
                      {suggested_sale_price_eur}
                      <span
                        className="prices-tooltip-sup"
                        data-tip={tooltip_tip}
                      >
                        i
                      </span>
                    </span>
                  </span>,
                  "EUR",
                ]}
                disabled={is_disabled}
              />
            </div>
            {sales_price_eur > 0 && (
              <div className="box-6">
                <p>
                  {sales_price_eur ? (
                    <strong>
                      {`≈ ${(sales_price_eur * getCurrencyRate("eur")).toFixed(
                        2
                      )} PLN,`}{" "}
                    </strong>
                  ) : (
                    ""
                  )}
                  narzut:{" "}
                  <strong>
                    {calculateMarkup({
                      purchase_price: purchase_price_brutto_value_eur,
                      sales_price: sales_price_eur,
                    })}
                    %
                  </strong>
                  , marża:{" "}
                  <strong>
                    {calculateMargin({
                      purchase_price: purchase_price_brutto_value_eur,
                      sales_price: sales_price_eur,
                    })}
                    %
                  </strong>
                </p>
              </div>
            )}
          </div>

          <Offers
            channel_products={product_data.channel_products}
            currency="eur"
            currency_price={sales_price_eur}
          />
          <p>
            <strong>CZK</strong>
          </p>
          <div className="row ai-c m-b-15">
            <div className="box-6">
              <Field
                component={Input}
                name="sales_price_czk"
                type="number"
                postfix={[
                  <span
                    onClick={() =>
                      change("sales_price_czk", suggested_sale_price_czk)
                    }
                  >
                    <span className="text-hoverable prices-tooltip-sup-container">
                      {suggested_sale_price_czk}
                      <span
                        className="prices-tooltip-sup"
                        data-tip={tooltip_tip}
                      >
                        i
                      </span>
                    </span>
                  </span>,
                  "CZK",
                ]}
                disabled={is_disabled}
              />
            </div>
            {sales_price_czk > 0 && (
              <div className="box-6">
                <p>
                  {sales_price_czk ? (
                    <strong>
                      {`≈ ${(sales_price_czk * getCurrencyRate("czk")).toFixed(
                        2
                      )} PLN,`}{" "}
                    </strong>
                  ) : (
                    ""
                  )}
                  narzut:{" "}
                  <strong>
                    {calculateMarkup({
                      purchase_price: purchase_price_brutto_value_czk,
                      sales_price: sales_price_czk,
                    })}
                    %
                  </strong>
                  , marża:{" "}
                  <strong>
                    {calculateMargin({
                      purchase_price: purchase_price_brutto_value_czk,
                      sales_price: sales_price_czk,
                    })}
                    %
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Offers
            channel_products={product_data.channel_products}
            currency="czk"
            currency_price={sales_price_czk}
          />
          <p>
            <strong>SEK</strong>
          </p>
          <div className="row ai-c m-b-15">
            <div className="box-6">
              <Field
                component={Input}
                name="sales_price_sek"
                type="number"
                postfix={[
                  <span
                    onClick={() =>
                      change("sales_price_sek", suggested_sale_price_sek)
                    }
                  >
                    <span className="text-hoverable prices-tooltip-sup-container">
                      {suggested_sale_price_sek}
                      <span
                        className="prices-tooltip-sup"
                        data-tip={tooltip_tip}
                      >
                        i
                      </span>
                    </span>
                  </span>,
                  "SEK",
                ]}
                disabled={is_disabled}
              />
            </div>
            {sales_price_sek > 0 && (
              <div className="box-6">
                <p>
                  {sales_price_sek ? (
                    <strong>
                      {`≈ ${(sales_price_sek * getCurrencyRate("sek")).toFixed(
                        2
                      )} PLN,`}{" "}
                    </strong>
                  ) : (
                    ""
                  )}
                  narzut:{" "}
                  <strong>
                    {calculateMarkup({
                      purchase_price: purchase_price_brutto_value_sek,
                      sales_price: sales_price_sek,
                    })}
                    %
                  </strong>
                  , marża:{" "}
                  <strong>
                    {calculateMargin({
                      purchase_price: purchase_price_brutto_value_sek,
                      sales_price: sales_price_sek,
                    })}
                    %
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Offers
            channel_products={product_data.channel_products}
            currency="sek"
            currency_price={sales_price_sek}
          />
          <p>
            <strong>GBP</strong>
          </p>
          <div className="row ai-c m-b-15">
            <div className="box-6">
              <Field
                component={Input}
                name="sales_price_gbp"
                type="number"
                postfix={[
                  <span
                    onClick={() =>
                      change("sales_price_gbp", suggested_sale_price_gbp)
                    }
                  >
                    <span className="text-hoverable prices-tooltip-sup-container">
                      {suggested_sale_price_gbp}
                      <span
                        className="prices-tooltip-sup"
                        data-tip={tooltip_tip}
                      >
                        i
                      </span>
                    </span>
                  </span>,
                  "GBP",
                ]}
                disabled={is_disabled}
              />
            </div>
            {sales_price_gbp > 0 && (
              <div className="box-6">
                <p>
                  {sales_price_gbp ? (
                    <strong>
                      {`≈ ${(sales_price_gbp * getCurrencyRate("gbp")).toFixed(
                        2
                      )} PLN,`}{" "}
                    </strong>
                  ) : (
                    ""
                  )}
                  narzut:{" "}
                  <strong>
                    {calculateMarkup({
                      purchase_price: purchase_price_brutto_value_gbp,
                      sales_price: sales_price_gbp,
                    })}
                    %
                  </strong>
                  , marża:{" "}
                  <strong>
                    {calculateMargin({
                      purchase_price: purchase_price_brutto_value_gbp,
                      sales_price: sales_price_gbp,
                    })}
                    %
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Offers
            channel_products={product_data.channel_products}
            currency="gbp"
            currency_price={sales_price_gbp}
          />
          <p>
            <strong>HUF</strong>
          </p>
          <div className="row ai-c m-b-15">
            <div className="box-6">
              <Field
                component={Input}
                name="sales_price_huf"
                type="number"
                postfix={[
                  <span
                    onClick={() =>
                      change("sales_price_huf", suggested_sale_price_huf)
                    }
                  >
                    <span className="text-hoverable prices-tooltip-sup-container">
                      {suggested_sale_price_huf}
                      <span
                        className="prices-tooltip-sup"
                        data-tip={tooltip_tip}
                      >
                        i
                      </span>
                    </span>
                  </span>,
                  "HUF",
                ]}
                disabled={is_disabled}
              />
            </div>
            {sales_price_huf > 0 && (
              <div className="box-6">
                <p>
                  {sales_price_huf ? (
                    <strong>
                      {`≈ ${(sales_price_huf * getCurrencyRate("huf")).toFixed(
                        2
                      )} PLN,`}{" "}
                    </strong>
                  ) : (
                    ""
                  )}
                  narzut:{" "}
                  <strong>
                    {calculateMarkup({
                      purchase_price: purchase_price_brutto_value_huf,
                      sales_price: sales_price_huf,
                    })}
                    %
                  </strong>
                  , marża:{" "}
                  <strong>
                    {calculateMargin({
                      purchase_price: purchase_price_brutto_value_huf,
                      sales_price: sales_price_huf,
                    })}
                    %
                  </strong>
                </p>
              </div>
            )}
          </div>
          <Offers
            channel_products={product_data.channel_products}
            currency="huf"
            currency_price={sales_price_huf}
          />
          <div className="product-prices-submit">
            <div>
              <Field
                label="Wyexportować ceny?"
                name="do_export_channel_products_prices"
                component={Checkbox}
              />
            </div>
            <button
              type="button"
              disabled={is_saving || pristine || is_disabled}
              onClick={handleSubmit(onSubmit)}
              className="btn btn-sm btn-green"
            >
              <span>{is_saving ? "Proszę czekać..." : "Aktualizuj"}</span>
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const form_name = "product_prices";
const selector = formValueSelector(form_name);

export default connect((state) => ({
  sales_price_pln: selector(state, "sales_price_pln"),
  sales_price_eur: selector(state, "sales_price_eur"),
  sales_price_czk: selector(state, "sales_price_czk"),
  sales_price_sek: selector(state, "sales_price_sek"),
  sales_price_gbp: selector(state, "sales_price_gbp"),
  sales_price_huf: selector(state, "sales_price_huf"),
}))(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
  })(EditPricesModal)
);
