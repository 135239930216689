import React from "react";

import TableSort from "./components/TableSort";
import TableFilters from "./components/TableFilters";
import TableRows from "./components/TableRows";
import Loader from "./components/Loader";

import { useProductsPrices } from "../../context/provider";

const Table = () => {
  const {
    state: { status },
  } = useProductsPrices();

  if (["invalid", "loading", "failure"].includes(status)) {
    return <Loader />;
  }

  return (
    <div className="main-list__table prices-table">
      <table>
        <TableSort />
        <TableFilters />
        <TableRows />
      </table>
    </div>
  );
};

export default Table;
