import React from "react";

import { useProductsPrices } from "../../../../../../context/provider";
import { ReactComponent as RemoveFilterIcon } from "icons/remove-filter.svg";

const FilterValues = ({ name, data }) => {
  const { removeFilterValue } = useProductsPrices();
  return (
    <div className="filter-values">
      {data.map(({ label, value }) => (
        <button
          key={value}
          className="btn-remove-filter"
          type="button"
          onClick={() => removeFilterValue({ name, value })}
        >
          <RemoveFilterIcon />
          <span>{label}</span>
        </button>
      ))}
    </div>
  );
};

export default FilterValues;
