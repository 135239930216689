import React, { useMemo, useReducer, useContext, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

import reducer, {
  initial_state,
  DATA_LOADING,
  CHANGE_SORT,
  SET_FILTER_VALUE,
  SET_FAST_FILTER_VALUE,
  REMOVE_FILTER_VALUE,
  DATA_FAILURE,
  DATA_SUCCESS,
  SET_META_PAGINATION,
  FILTER_PRODUCTS,
  SET_PRODUCT_ID,
  UPDATE_PRODUCT,
  SET_OPEN_CALCULATOR,
  UPDATE_PRODUCT_CUSTOM_PURCHASE_PRICE,

} from "./reducer";

// CONTEXT ===================================
const ProductsPricesContext = React.createContext();

const sort = [
  {
    label: "Produkt",
    name: "name_sku_or_product_group_sku",
    predefined_sorts: false,
  },
  {
    label: "Producent",
    name: "producer_name",
    style: { width: 130 },
    predefined_sorts: false,
  },
  {
    label: "Dostawca",
    name: "supplier_name",
    style: { width: 130 },
    predefined_sorts: false,
  },
  {
    label: "Status",
    name: "status",
    style: { width: 130 },
    predefined_sorts: false,
    sortable: false,
  },
  {
    label: "Zakup (netto)",
    name: "purchase_price_netto_value",
    style: { width: 150 },
    predefined_sorts: true,
  },
  {
    label: "Zakup (brutto)",
    name: "purchase_price_brutto_value",
    style: { width: 150 },
    predefined_sorts: true,
  },
  {
    label: "Cena PLN",
    name: "sales_price_pln",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Cena EUR",
    name: "sales_price_eur",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Cena CZK",
    name: "sales_price_czk",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Cena SEK",
    name: "sales_price_sek",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Cena GBP",
    name: "sales_price_gbp",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Cena HUF",
    name: "sales_price_huf",
    style: { width: 120 },
    predefined_sorts: true,
  },
  {
    label: "Akcje",
    name: "actions",
    sortable: false,
    style: { width: 100 },
    predefined_sorts: false,
  },
];

// narzut
export const calculateMarkup = ({ purchase_price, sales_price }) =>
  sales_price
    ? (((sales_price - purchase_price) / purchase_price) * 100).toFixed(2)
    : 0;

// marża
export const calculateMargin = ({ purchase_price, sales_price }) =>
  sales_price
    ? (((sales_price - purchase_price) / sales_price) * 100).toFixed(2)
    : 0;

export const calculateSuggestedPrice = ({
  purchase_price,
  exchange_rate,
  currency,
}) => {
  // cena_sprzedaży = cena_zakupu * ( 1 + narzut ) * kurs_waluty * mnożnik
  // narzut = 170 / (Math.log(cena_zakupu + 1) ** 0.7) / 100
  // (kurs_waluty dla PLN = 1, mnożnik dla wszystkich walut = 1, dla EUR = 1.2)

  if (!purchase_price || parseFloat(purchase_price) === 0) {
    return 0;
  }

  const purchasePriceNum = parseFloat(purchase_price);
  const markup = 170 / Math.log(purchasePriceNum + 1) ** 0.7 / 100;
  const multiplier = currency === "eur" ? 1.12 : 1;

  return (
    ((purchasePriceNum * (1 + markup)) / exchange_rate) *
    multiplier
  ).toFixed(2);
};

function ProductsPricesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initial_state);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      dispatch({ type: DATA_LOADING });
      const { products, exchange_rates, product_suppliers } = await client.get(
        "/products/products_prices"
      );

      const supplier_names = [
        ...new Set(product_suppliers.map(({ supplier_name }) => supplier_name)),
      ].filter(Boolean);

      const products_data = products.map((product) => {
        const product_supplier = product_suppliers.find(
          ({ sku }) => sku === product.sku
        );
        return {
          ...product,
          expano_root_id: product_supplier?.expano_root_id,
          expano_wms_id: product_supplier?.expano_wms_id,
          product_supplier,
        };
      });

      dispatch({
        type: DATA_SUCCESS,
        payload: {
          products: products_data,
          exchange_rates,
          supplier_names,
        },
      });
    } catch (error) {
      dispatch({ type: DATA_FAILURE });
      toastr.error(
        "Błąd",
        error?.response?.data?.message || "Wystąpił błąd podczas pobierania"
      );
      if (error.response.status === 403) {
        window.location.pathname = "/";
      }
    }
  };

  const changeSort = ({ column, direction }) =>
    dispatch({
      type: CHANGE_SORT,
      payload: { column, direction },
    });

  const setFilterValue = ({ name, value, label }) => {
    dispatch({
      type: SET_FILTER_VALUE,
      payload: { name, value, label },
    });
    dispatch({ type: FILTER_PRODUCTS });
  };
  const setFastFilterValue = ({ name, value }) => {
    dispatch({
      type: SET_FAST_FILTER_VALUE,
      payload: { name, value },
    });
    dispatch({ type: FILTER_PRODUCTS });
  };

  const removeFilterValue = ({ name, value }) => {
    dispatch({
      type: REMOVE_FILTER_VALUE,
      payload: { name, value },
    });
    dispatch({ type: FILTER_PRODUCTS });
  };

  const setMetaPagination = ({ page, per_page }) =>
    dispatch({ type: SET_META_PAGINATION, payload: { page, per_page } });

  const setProductId = (product_id) =>
    dispatch({ type: SET_PRODUCT_ID, payload: { product_id } });

  const updateProduct = async (product_id, values) => {
    try {
      await client.put(`/products/${product_id}`, values);
      dispatch({ type: UPDATE_PRODUCT, payload: { product_id, values } });
    } catch (error) {
      throw error;
    }
  };

  const updateCustomPurchasePrice = async (
    product_id,
    custom_purchase_price_netto
  ) => {
    try {
      await client.put(`/products/${product_id}`, {
        custom_purchase_price_netto,
      });
      const { data: product_data } = await client.get(
        `/products/${product_id}?context=products_prices`
      );
      dispatch({
        type: UPDATE_PRODUCT_CUSTOM_PURCHASE_PRICE,
        payload: { product_id, data: product_data },
      });
    } catch (error) {
      throw error;
    }
  };

  const setOpenCalculator = (is_open_calculator) =>
    dispatch({ type: SET_OPEN_CALCULATOR, payload: { is_open_calculator } });

  const value = useMemo(() => {
    return {
      state,
      sort,
      changeSort,
      setFilterValue,
      setFastFilterValue,
      removeFilterValue,
      setMetaPagination,
      setProductId,
      calculateMarkup,
      calculateMargin,
      updateProduct,
      setOpenCalculator,
      calculateSuggestedPrice,
      updateCustomPurchasePrice,
    };
  }, [state]);

  return (
    <ProductsPricesContext.Provider value={value}>
      {children}
    </ProductsPricesContext.Provider>
  );
}

const useProductsPrices = () => useContext(ProductsPricesContext);
export { useProductsPrices };
export default ProductsPricesProvider;
