import React, { useState, useEffect } from "react";

const Loader = () => {
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(60000); // Initial duration: 60s

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) return 100;
        return oldProgress + 100 / (duration / 500); // Increase per 500ms
      });
    }, 500);

    return () => clearInterval(interval);
  }, [duration]);

  useEffect(() => {
    if (progress >= 97) {
      setProgress(80); // Reset progress
      setDuration((prev) => prev + 10000); // Extend by 30s
    }
  }, [progress]);

  return (
    <div className="main-list__loader">
      <div className="progress-bar-container">
        <p>Trwa pobieranie... {Math.round(progress)}%</p>
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
