import React, { useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Input } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { CircleLabel, CreatableSelect } from "components";
import { updateChannelProduct } from "actions/product_group/channel_product_group";
import AiModal from "./AiModal";

const InputField = (props) => (
  <Input
    {...props}
    input={{
      ...props.input,
      placeholder: props.placeholder,
    }}
  />
);

const InputLabel = ({
  type,
  name,
  sku,
  is_allegro,
  offer_url,
  handleOpenAiModal,
}) => (
  <div
    className="gallery-item__header__title"
    style={{
      paddingLeft: 0,
      marginLeft: -30,
      marginTop: 10,
    }}
  >
    <CircleLabel type={type} />
    <span className="sku">{sku}</span>
    <span className="name">{name}</span>
    {is_allegro && (
      <>
        {/* eslint-disable-next-line */}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={offer_url}
          data-tip="Przejdź do oferty"
          className={`offer-dot ${
            !!offer_url ? "" : "disabled"
          }`}
        />
        {!!offer_url && (
          <ReactTooltip
            arrowColor="#ebecf3"
            backgroundColor="#ebecf3"
            textColor="#74788d"
            place="left"
            type="light"
            effect="float"
          />
        )}
      </>
    )}
    <button
      type="button"
      className="btn btn-sm btn-blue m-l-10"
      onClick={() => handleOpenAiModal(true)}
    >
      <span>Generuj AI</span>
    </button>
  </div>
);

const ProductForm = ({
  channel,
  initialValues,
  forms,
  form,
  change,
  product: { sku, name },
  offer_url,
  id,
}) => {
  const [is_ai_modal_open, handleOpenAiModal] = useState(
    false
  );
  const form_values = forms[form]?.values;
  const is_wsf = channel.type === "Wsf::Channel";
  const is_allegro = channel.type === "Allegro::Channel";
  const calculateCharacters = () => {
    const name_length = form_values?.name?.length || 0;
    const subname_length =
      form_values?.subname?.length || 0;

    return is_wsf
      ? name_length + subname_length
      : name_length;
  };
  return (
    <>
      {is_ai_modal_open && (
        <AiModal
          handleClose={() => handleOpenAiModal(false)}
          id={id}
        />
      )}
      <Field
        component={InputField}
        name="name"
        placeholder="Tytuł oferty"
        label={
          <InputLabel
            type="p"
            sku={sku}
            name={name}
            is_allegro={is_allegro}
            offer_url={offer_url}
            handleOpenAiModal={handleOpenAiModal}
          />
        }
      />
      {is_wsf && (
        <Field
          component={InputField}
          name="subname"
          placeholder="Podtytuł oferty"
        />
      )}
      <p
        style={{
          fontSize: "13px",
          lineHeight: "20px",
          fontWeight: 300,
          color: "#a2a5b9",
          marginTop: -7,
          marginLeft: 5,
          marginBottom: 10,
        }}
      >
        {calculateCharacters()} znaków
      </p>
      {(is_wsf || is_allegro) && (
        <>
          <Field
            name="id"
            component={InputField}
            type="hidden"
          />
          <Field
            name="keyword_list"
            component={InputField}
            type="hidden"
          />
          <CreatableSelect
            onChange={(value) =>
              change("keyword_list", value)
            }
            placeholder="Słowa kluczowe"
            defaultValue={
              initialValues.keyword_list?.length > 0
                ? initialValues.keyword_list.map(
                    (item) => ({ label: item, value: item })
                  )
                : []
            }
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  channel_product_group: {
    data: { channel },
  },
  form,
}) => ({
  channel,
  forms: form,
  onSubmit: ({ id, name, subname, keyword_list }) => {
    updateChannelProduct(id, {
      name,
      subname,
      keyword_list,
    });
  },
});

export default connect(mapStateToProps)(
  reduxForm({
    enableReinitialize: true,
  })(ProductForm)
);
