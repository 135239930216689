import React from "react";
import { useQuery } from "@tanstack/react-query";
import { client } from "utils/api";

import { PageLoader } from "components";

const ProductSearch = ({ history, location }) => {
  useQuery(
    ["search", location.search],
    () => client.get(`/products/search${location.search}`),
    {
      select: (res) => res.data,
      retry: false,
      onSuccess: ({ product_id, product_group_id }) => {
        history.push(
          `/product_groups/${product_group_id}/specification/products/${product_id}`
        );
      },
      onError: () => {
        history.push("/products");
      },
    }
  );
  return <PageLoader />;
};

export default ProductSearch;
