import React from "react";
import { Main } from "components";
import { CSVLink } from "react-csv";
import { ReactComponent as ChartIcon } from "icons/chart.svg";
import ProductsPricesProvider, { useProductsPrices } from "./context/provider";
import Table from "./components/Table";
import Pagination from "./components/Pagination";
import CalculatorModal from "./components/CalculatorModal";

const AsideButtons = () => {
  const {
    state: { csv_data, status },
    setOpenCalculator,
  } = useProductsPrices();
  if (status !== "success") {
    return null;
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-blue nowrap"
        onClick={() => setOpenCalculator(true)}
      >
        <span>Kalkulator</span>
      </button>
      <CSVLink data={csv_data} filename="ceny_produktow.csv">
        <button type="button" className="btn btn-sm btn-green">
          <span>Eksportuj do .csv</span>
        </button>
      </CSVLink>
    </>
  );
};

const ProductsPrices = () => (
  <ProductsPricesProvider>
    <Main
      page={{
        name: "Analiza",
        icon: <ChartIcon />,
        breadcrumbs: [
          {
            name: "Analiza",
          },
        ],
        buttons: <AsideButtons />,
      }}
    >
      <CalculatorModal />
      <div className="main-list">
        <Table />
        <Pagination />
      </div>
    </Main>
  </ProductsPricesProvider>
);

export default ProductsPrices;
