import React from "react";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "icons/sort-arrow.svg";
import { useProductsPrices } from "../../../../context/provider";
import Select from "./components/Select";

const predefined_sort_options = [
  { label: "większa od", value: ">" },
  { label: "większa lub równa", value: ">=" },
  { label: "mniejsza od", value: "<" },
  { label: "mniejsza lub równa", value: "<=" },
  { label: "równa 0", value: "brak" },
];

const predefined_sort_with_margin_and_markup_options = [
  {
    label: "--Cena--",
    options: [
      { label: "większa od", value: ">" },
      { label: "większa lub równa", value: ">=" },
      { label: "mniejsza od", value: "<" },
      { label: "mniejsza lub równa", value: "<=" },
      { label: "równa 0", value: "brak" },
      { label: "mniejsza niż sugerowana", value: "s<" },
    ],
  },
  {
    label: "--Marża--",
    options: [
      { label: "większa od", value: "m>" },
      { label: "większa lub równa", value: "m>=" },
      { label: "mniejsza od", value: "m<" },
      { label: "mniejsza lub równa", value: "m<=" },
    ],
  },
  {
    label: "--Narzut--",
    options: [
      { label: "większy od", value: "n>" },
      { label: "większy lub równa", value: "n>=" },
      { label: "mniejszy od", value: "n<" },
      { label: "mniejszy lub równa", value: "n<=" },
    ],
  },
];

const status_options = [
  { label: "Aktywny", value: "active" },
  { label: "Nieaktywny", value: "inactive" },
  { label: "Do wyprzedania", value: "discontinued" },
];
const product_type_options = [
  { label: "Bundle", value: "is:bundle" },
  { label: "Virtual", value: "is:virtual" },
  { label: "Unit", value: "is:unit" },
];

const checkDirectionToChange = ({ is_current_column, active_direction }) => {
  if (is_current_column) {
    return active_direction === "asc" ? "desc" : "asc";
  }
  return "desc";
};

const TableSort = () => {
  const {
    sort,
    state: { active_sort },
    changeSort,
    setFilterValue,
  } = useProductsPrices();

  return (
    <thead className="table-sort">
      <tr>
        {sort.map(
          ({
            label = "",
            name = "",
            sortable = true,
            style = {},
            predefined_sorts,
          }) =>
            sortable ? (
              <th style={style} key={name}>
                {name === "name_sku_or_product_group_sku" ? (
                  <div className="sort-group">
                    <button
                      type="button"
                      onClick={() =>
                        changeSort({
                          column: "sku",
                          direction: checkDirectionToChange({
                            is_current_column: active_sort.column === "sku",
                            active_direction: active_sort.direction,
                          }),
                        })
                      }
                      className={classNames("sort-button", {
                        active: active_sort.column === "sku",
                        asc:
                          active_sort.column === "sku" &&
                          active_sort.direction === "asc",
                      })}
                    >
                      <span>SKU</span>
                      <ArrowIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        changeSort({
                          column: "name",
                          direction: checkDirectionToChange({
                            is_current_column: active_sort.column === "name",
                            active_direction: active_sort.direction,
                          }),
                        })
                      }
                      className={classNames("sort-button", {
                        active: active_sort.column === "name",
                        asc:
                          active_sort.column === "name" &&
                          active_sort.direction === "asc",
                      })}
                    >
                      <span>Nazwa produktu</span>
                      <ArrowIcon />
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() =>
                      changeSort({
                        column: name,
                        direction: checkDirectionToChange({
                          is_current_column: active_sort.column === name,
                          active_direction: active_sort.direction,
                        }),
                      })
                    }
                    className={classNames("sort-button", {
                      active: active_sort.column === name,
                      asc:
                        active_sort.column === name &&
                        active_sort.direction === "asc",
                    })}
                  >
                    <span>{label}</span>
                    <ArrowIcon />
                  </button>
                )}

                <div className="m-t-5">
                  {name === "name_sku_or_product_group_sku" && (
                    <Select
                      options={product_type_options}
                      onChange={(a) => {
                        setFilterValue({
                          name,
                          value: a.value,
                          label: a.label,
                        });
                      }}
                    />
                  )}
                  {name === "status" && (
                    <Select
                      options={status_options}
                      onChange={(a) => {
                        setFilterValue({
                          name,
                          value: a.value,
                          label: a.label,
                        });
                      }}
                    />
                  )}
                  {predefined_sorts && (
                    <Select
                      options={
                        [
                          "purchase_price_brutto_value",
                          "purchase_price_netto_value",
                        ].includes(name)
                          ? predefined_sort_options
                          : predefined_sort_with_margin_and_markup_options
                      }
                      onChange={(a) => {
                        if (a.value === "brak") {
                          setFilterValue({
                            name,
                            value: a.value,
                            label: a.value,
                          });
                        } else if (a.value === "s<") {
                          setFilterValue({
                            name,
                            value: `${name}_lower_than_suggested`,
                            label: "mniejsza niż sugerowana",
                          });
                        } else {
                          document.querySelector(`#sort_${name} input`).value =
                            a.value;
                          document.querySelector(`#sort_${name} input`).focus();
                        }
                      }}
                    />
                  )}
                </div>
              </th>
            ) : (
              <th style={style} key={name}>
                <span>{label}</span>
                {name === "status" && (
                  <div className="m-t-5">
                    <Select
                      options={status_options}
                      onChange={(a) => {
                        setFilterValue({
                          name,
                          value: a.value,
                          label: a.label,
                        });
                      }}
                    />
                  </div>
                )}
              </th>
            )
        )}
      </tr>
    </thead>
  );
};

export default TableSort;
