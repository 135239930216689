import React from "react";

import FilterValues from "./components/FilterValues";
import Input from "./components/Input";
import { useProductsPrices } from "../../../../context/provider";

const TableFilters = () => {
  const {
    state: { fast_filters, filters, supplier_names },
    setFilterValue,
    setFastFilterValue,
  } = useProductsPrices();

  return (
    <thead className="table-filters">
      <tr>
        {filters.map((filter, index) =>
          filter.name === "action" ? (
            <th key={filter?.name || index} id={`sort_${filter.name}`} />
          ) : (
            <th key={filter?.name || index} id={`sort_${filter.name}`}>
              <Input
                {...filter}
                options={supplier_names}
                handleSubmit={(value) => {
                  setFastFilterValue({ name: filter.name, value: "" });
                  setFilterValue({
                    name: filter.name,
                    value,
                    label: value,
                  });
                }}
                onChange={({ target: { value } }) =>
                  setFastFilterValue({ name: filter.name, value })
                }
                value={fast_filters[filter.name]}
              />
              {filter?.values?.length > 0 && (
                <FilterValues name={filter.name} data={filter.values} />
              )}
            </th>
          )
        )}
      </tr>
    </thead>
  );
};

export default TableFilters;
