import {
  calculateMarkup,
  calculateMargin,
  calculateSuggestedPrice,
} from "./provider";

export const CHANGE_SORT = "CHANGE_SORT";
export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const SET_FAST_FILTER_VALUE = "SET_FAST_FILTER_VALUE";
export const REMOVE_FILTER_VALUE = "REMOVE_FILTER_VALUE";
export const DATA_LOADING = "DATA_LOADING";
export const DATA_SUCCESS = "DATA_SUCCESS";
export const DATA_FAILURE = "DATA_FAILURE";
export const SET_META_PAGINATION = "SET_META_PAGINATION";
export const SET_ACTIVE_SORT = "SET_ACTIVE_SORT";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SET_OPEN_CALCULATOR = "SET_OPEN_CALCULATOR";
export const UPDATE_PRODUCT_CUSTOM_PURCHASE_PRICE =
  "UPDATE_PRODUCT_CUSTOM_PURCHASE_PRICE";

const filters = [
  {
    name: "name_sku_or_product_group_sku",
    sort_id: "sku",
    values: [],
  },
  {
    name: "producer_name",
    sort_id: "producer_name",
    values: [],
  },
  {
    name: "supplier_name",
    sort_id: "supplier_name",
    values: [],
  },
  {
    name: "status",
    sort_id: "status",
    values: [{ label: "Aktywny", value: "active" }],
  },
  {
    name: "purchase_price_netto_value",
    sort_id: "purchase_price_netto_value",
    values: [],
  },
  {
    name: "purchase_price_brutto_value",
    sort_id: "purchase_price_brutto_value",
    values: [],
  },
  {
    name: "sales_price_pln",
    sort_id: "sales_price_pln",
    values: [],
  },
  {
    name: "sales_price_eur",
    sort_id: "sales_price_eur",
    values: [],
  },
  {
    name: "sales_price_czk",
    sort_id: "sales_price_czk",
    values: [],
  },
  {
    name: "sales_price_sek",
    sort_id: "sales_price_sek",
    values: [],
  },
  {
    name: "sales_price_gbp",
    sort_id: "sales_price_gbp",
    values: [],
  },
  {
    name: "sales_price_huf",
    sort_id: "sales_price_huf",
    values: [],
  },
  {
    name: "action",
    sort_id: "action",
    values: [],
  },
];

const csv_columns = [
  { label: "SKU", name: "sku" },
  { label: "Nazwa produktu", name: "name" },
  {
    label: "Cena zakupu (brutto)",
    name: "purchase_price_brutto_value",
  },
  {
    label: "Cena PLN",
    name: "sales_price_pln",
  },
  {
    label: "Cena EUR",
    name: "sales_price_eur",
  },
  {
    label: "Cena CZK",
    name: "sales_price_czk",
  },
  {
    label: "Cena SEK",
    name: "sales_price_sek",
  },
  {
    label: "Cena GBP",
    name: "sales_price_gbp",
  },
  {
    label: "Cena HUF",
    name: "sales_price_huf",
  },
];

const createFastFilters = () => {
  const obj = {};
  filters.forEach((filter) => (obj[filter.name] = ""));
  return obj;
};

const currencies = ["czk", "eur", "gbp", "huf", "pln", "sek"];

export const initial_state = {
  filters,
  fast_filters: createFastFilters(),
  status: "invalid",
  active_sort: {
    column: "status",
    direction: "desc",
  },
  meta: {
    page: 1,
    total_pages: 1,
    per_page: 100,
    total_count: 1,
  },
  all_products: [],
  exchange_rates: [],
  products: [],
  product_id: null,
  csv_data: [],
  is_open_calculator: false,
  supplier_names: [],
};

const paginateProducts = ({ page, per_page, all_products }) => {
  const start = (page - 1) * per_page;
  const end = page * per_page;
  return all_products.slice(start, end);
};

const sortProducts = ({ products, column, direction }) => {
  if (
    [
      "purchase_price_brutto_value",
      "purchase_price_netto_value",
      "sales_price_pln",
      "sales_price_eur",
      "sales_price_czk",
      "sales_price_sek",
      "sales_price_gbp",
      "sales_price_huf",
    ].includes(column)
  ) {
    const product_without_prices = products.filter(
      (props) => props[column] === null || parseFloat(props[column]) === 0
    );

    const product_with_prices = products
      .filter((props) => parseFloat(props[column]) > 0)
      .sort((a, b) =>
        direction === "desc"
          ? Number(b[column]) - Number(a[column])
          : Number(a[column]) - Number(b[column])
      );

    const all_products = [...product_with_prices, ...product_without_prices];

    return all_products;
  }

  if (["name"].includes(column)) {
    return products.sort((a, b) =>
      direction === "desc"
        ? b.name.localeCompare(a.name)
        : a.name.localeCompare(b.name)
    );
  }

  if (["sku"].includes(column)) {
    return products.sort((a, b) =>
      direction === "desc"
        ? b.sku.localeCompare(a.sku)
        : a.sku.localeCompare(b.sku)
    );
  }

  if (["producer_name"].includes(column)) {
    return products.sort((a, b) =>
      direction === "desc"
        ? b.producer.name.localeCompare(a.producer.name)
        : a.producer.name.localeCompare(b.producer.name)
    );
  }

  if (["supplier_name"].includes(column)) {
    const product_without_supplier_name = products.filter(
      ({ product_supplier }) => !!!product_supplier?.supplier_name
    );

    const product_with_supplier_name = products
      .filter(({ product_supplier }) => !!product_supplier?.supplier_name)
      .sort((a, b) =>
        direction === "desc"
          ? b.product_supplier.supplier_name.localeCompare(
              a.product_supplier.supplier_name
            )
          : a.product_supplier.supplier_name.localeCompare(
              b.product_supplier.supplier_name
            )
      );

    const all_products = [
      ...product_with_supplier_name,
      ...product_without_supplier_name,
    ];

    return all_products;
  }

  return products;
};

const filterProducts = ({ products, filters, fast_filters }) => {
  const active_filters =
    filters?.filter((filter) => filter?.values?.length > 0) || [];

  // active filters
  for (const { values, name } of active_filters) {
    if (["name_sku_or_product_group_sku"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          if (item.startsWith("is:bundle")) {
            return product.is_bundle;
          }
          if (item.startsWith("is:virtual")) {
            return product.is_virtual;
          }
          if (item.startsWith("is:unit")) {
            return !product.is_bundle && !product.is_virtual;
          }
          return (
            product.name.toLowerCase().includes(item.toLowerCase()) ||
            product.sku.toLowerCase().includes(item.toLowerCase()) ||
            product?.product_group?.sku === item
          );
        });
      });
    }

    if (["producer_name"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          return product?.producer?.name
            ?.toLowerCase()
            .includes(item.toLowerCase());
        });
      });
    }

    if (["supplier_name"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) => {
        return items.every((item) => {
          return product?.product_supplier?.supplier_name
            ?.toLowerCase()
            .includes(item.toLowerCase());
        });
      });
    }

    if (["status"].includes(name)) {
      const items = values.map(({ value }) => value);
      products = products.filter((product) =>
        items.some((item) => product.status === item)
      );
    }
    if (
      [
        "purchase_price_brutto_value",
        "purchase_price_netto_value",
        "sales_price_pln",
        "sales_price_eur",
        "sales_price_czk",
        "sales_price_sek",
        "sales_price_gbp",
        "sales_price_huf",
      ].includes(name)
    ) {
      const items = values.map(({ value }) => value);

      for (const item of items) {
        switch (true) {
          case item.startsWith("m>="): {
            const value = item.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) >= Number(value);
            });
            break;
          }
          case item.startsWith("m>"): {
            const value = item.slice(2).replace(",", ".");

            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) > Number(value);
            });
            break;
          }
          case item.startsWith("m<="): {
            const value = item.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) <= Number(value);
            });
            break;
          }
          case item.startsWith("m<"): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) < Number(value);
            });
            break;
          }
          case item.startsWith("n>="): {
            const value = item.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) >= Number(value);
            });
            break;
          }
          case item.startsWith("n>"): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) > Number(value);
            });
            break;
          }
          case item.startsWith("n<="): {
            const value = item.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) <= Number(value);
            });
            break;
          }
          case item.startsWith("n<"): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) < Number(value);
            });
            break;
          }
          case item.startsWith(">="): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) >= Number(value);
            });
            break;
          }
          case item.startsWith(">"): {
            const value = item.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) > Number(value);
            });
            break;
          }
          case item.startsWith("<="): {
            const value = item.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) <= Number(value);
            });
            break;
          }
          case item.startsWith("<"): {
            const value = item.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) < Number(value);
            });
            break;
          }
          case item === "brak": {
            products = products.filter((product) => {
              return !product[name] || parseFloat(product[name]) === 0;
            });
            break;
          }
          case item.endsWith("lower_than_suggested"): {
            products = products.filter((product) => product[item]);
            break;
          }
          default: {
            products = products.filter((product) => {
              return Number(product[name]) === Number(item.replace(",", "."));
            });
          }
        }
      }
    }
  }

  // fast filters

  Object.keys(fast_filters).forEach((name) => {
    const value = fast_filters[name];
    if (value) {
      if (["name_sku_or_product_group_sku"].includes(name)) {
        products = products.filter((product) => {
          return (
            product.name.toLowerCase().includes(value.toLowerCase()) ||
            product.sku.toLowerCase().includes(value.toLowerCase()) ||
            product?.product_group?.sku === value
          );
        });
      }

      if (["producer_name"].includes(name)) {
        products = products.filter((product) => {
          return product?.producer?.name
            ?.toLowerCase()
            .includes(value.toLowerCase());
        });
      }

      if (["supplier_name"].includes(name)) {
        products = products.filter((product) => {
          return product?.product_supplier?.supplier_name
            ?.toLowerCase()
            .includes(value.toLowerCase());
        });
      }

      if (["status"].includes(name)) {
        products = products.filter((product) => product.status === value);
      }
      if (
        [
          "purchase_price_brutto_value",
          "purchase_price_netto_value",
          "sales_price_pln",
          "sales_price_eur",
          "sales_price_czk",
          "sales_price_sek",
          "sales_price_gbp",
          "sales_price_huf",
        ].includes(name)
      ) {
        switch (true) {
          case value.startsWith("m>="): {
            const new_value = value.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) >= Number(new_value);
            });
            break;
          }
          case value.startsWith("m>"): {
            const new_value = value.slice(2).replace(",", ".");

            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) > Number(new_value);
            });
            break;
          }
          case value.startsWith("m<="): {
            const new_value = value.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) <= Number(new_value);
            });
            break;
          }
          case value.startsWith("m<"): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_margin`]) < Number(new_value);
            });
            break;
          }
          case value.startsWith("n>="): {
            const new_value = value.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) >= Number(new_value);
            });
            break;
          }
          case value.startsWith("n>"): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) > Number(new_value);
            });
            break;
          }
          case value.startsWith("n<="): {
            const new_value = value.slice(3).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) <= Number(new_value);
            });
            break;
          }
          case value.startsWith("n<"): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[`${name}_markup`]) < Number(new_value);
            });
            break;
          }
          case value.startsWith(">="): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) >= Number(new_value);
            });
            break;
          }
          case value.startsWith(">"): {
            const new_value = value.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) > Number(new_value);
            });
            break;
          }
          case value.startsWith("<="): {
            const new_value = value.slice(2).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) <= Number(new_value);
            });
            break;
          }
          case value.startsWith("<"): {
            const new_value = value.slice(1).replace(",", ".");
            products = products.filter((product) => {
              return Number(product[name]) < Number(new_value);
            });
            break;
          }
          case value === "brak": {
            products = products.filter((product) => {
              return !product[name] || parseFloat(product[name]) === 0;
            });
            break;
          }
          case value.endsWith("lower_than_suggested"): {
            products = products.filter((product) => product[value]);
            break;
          }
          default: {
            products = products.filter((product) => {
              return Number(product[name]) === Number(value.replace(",", "."));
            });
          }
        }
      }
    }
  });

  return products;
};

const createCsvData = (data) => {
  const csv = [csv_columns.map(({ label }) => label)];
  for (const item of data) {
    const item_array = [];
    for (const column of csv_columns) {
      if (["sku", "name"].includes(column.name)) {
        item_array.push(item[column.name] || "");
      } else {
        item_array.push(String(item[column.name] || "").replace(".", ","));
      }
    }
    csv.push(item_array);
  }
  return csv;
};

const getCurrencyRate = (exchange_rates, currency) =>
  exchange_rates.find((item) => item.currency === currency)?.rate || 0;

const calculateProductPriceFields = (product, exchange_rates) => {
  const markup_and_margin_data = currencies.reduce((acc, currency) => {
    const purchase_price = product.purchase_price_brutto_value;

    const suggested_sale_price = calculateSuggestedPrice({
      purchase_price,
      exchange_rate: 1,
      currency,
    });
    if (currency === "pln") {
      acc["sales_price_pln_markup"] = calculateMarkup({
        purchase_price: product.purchase_price_brutto_value,
        sales_price: product.sales_price_pln,
      });
      acc["sales_price_pln_margin"] = calculateMargin({
        purchase_price: product.purchase_price_brutto_value,
        sales_price: product.sales_price_pln,
      });
      acc["suggested_sale_price_pln"] = suggested_sale_price;
      acc["sales_price_pln_lower_than_suggested"] =
        !product.sales_price_pln || parseFloat(product.sales_price_pln) === 0
          ? false
          : parseFloat(product.sales_price_pln) <
            parseFloat(suggested_sale_price);
    } else {
      const exchange_rate = getCurrencyRate(exchange_rates, currency);
      const purchase_price_brutto_value_currency =
        purchase_price / exchange_rate;
      const suggested_sale_price = calculateSuggestedPrice({
        purchase_price: product.purchase_price_brutto_value,
        exchange_rate,
        currency,
      });
      acc[`purchase_price_brutto_value_${currency}`] =
        purchase_price_brutto_value_currency.toFixed(2);
      acc[`sales_price_${currency}_markup`] = calculateMarkup({
        purchase_price: purchase_price_brutto_value_currency,
        sales_price: product[`sales_price_${currency}`],
      });
      acc[`sales_price_${currency}_margin`] = calculateMargin({
        purchase_price: purchase_price_brutto_value_currency,
        sales_price: product[`sales_price_${currency}`],
      });
      acc[`suggested_sale_price_${currency}`] = suggested_sale_price;
      acc[`sales_price_${currency}_lower_than_suggested`] =
        !product[`sales_price_${currency}`] ||
        parseFloat(product[`sales_price_${currency}`]) === 0
          ? false
          : parseFloat(product[`sales_price_${currency}`]) <
            parseFloat(suggested_sale_price);
    }
    return acc;
  }, {});
  return { ...product, ...markup_and_margin_data };
};

const calculateMarkupAndMargin = (products, exchange_rates) =>
  products.map((product) =>
    calculateProductPriceFields(product, exchange_rates)
  );

const mainListReducer = (state = initial_state, { type, payload }) => {
  switch (type) {
    case DATA_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case DATA_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case DATA_SUCCESS: {
      const all_products = calculateMarkupAndMargin(
        payload.products,
        payload.exchange_rates
      );

      const filtered_products = filterProducts({
        products: all_products,
        filters: state.filters,
        fast_filters: state.fast_filters,
      });

      const products = paginateProducts({
        all_products: filtered_products,
        per_page: state.meta.per_page,
        page: state.meta.page,
      });
      return {
        ...state,
        status: "success",
        all_products,
        products,
        csv_data: createCsvData(all_products),
        exchange_rates: payload.exchange_rates,
        supplier_names: payload.supplier_names,
        meta: {
          ...state.meta,
          total_pages: Math.ceil(all_products.length / state.meta.per_page),
          total_count: all_products.length,
        },
      };
    }
    case SET_FILTER_VALUE: {
      const filters = [...state.filters];

      const index = filters.indexOf(
        filters?.find((filter) => filter?.name === payload.name)
      );
      if (index > -1) {
        filters[index].values = [
          ...filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
          { value: payload.value, label: payload.label },
        ];
      }
      return {
        ...state,
        meta: { ...state.meta, page: 1 },
        filters,
      };
    }
    case SET_FAST_FILTER_VALUE: {
      const fast_filters = { ...state.fast_filters };

      fast_filters[payload.name] = payload.value;

      return {
        ...state,
        meta: { ...state.meta, page: 1 },
        fast_filters,
      };
    }
    case REMOVE_FILTER_VALUE: {
      const filters = [...state.filters];
      const index = filters.indexOf(
        filters.find((filter) => filter?.name === payload.name)
      );
      if (index > -1) {
        filters[index].values = [
          ...filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
        ];
      }
      return { ...state, filters };
    }
    case SET_META_PAGINATION: {
      const filtered_products = filterProducts({
        products: [...state.all_products],
        filters: state.filters,
        fast_filters: state.fast_filters,
      });

      const all_products = sortProducts({
        products: filtered_products,
        ...state.active_sort,
      });

      const products = paginateProducts({
        all_products,
        per_page: payload.per_page,
        page: payload.page,
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: payload.per_page,
          page: payload.page,
          total_pages: Math.ceil(all_products.length / payload.per_page),
          total_count: all_products.length,
        },
        products,
      };
    }
    case CHANGE_SORT: {
      const filtered_products = filterProducts({
        products: [...state.all_products],
        filters: state.filters,
        fast_filters: state.fast_filters,
      });
      const all_products = sortProducts({
        products: filtered_products,
        column: payload.column,
        direction: payload.direction,
      });

      const products = paginateProducts({
        all_products,
        per_page: state.meta.per_page,
        page: 1,
      });

      return {
        ...state,
        active_sort: {
          column: payload.column,
          direction: payload.direction,
        },
        meta: {
          ...state.meta,
          page: 1,
        },
        products,
      };
    }
    case FILTER_PRODUCTS: {
      const filtered_products = filterProducts({
        products: [...state.all_products],
        filters: state.filters,
        fast_filters: state.fast_filters,
      });
      const all_products = sortProducts({
        products: filtered_products,
        ...state.active_sort,
      });

      const products = paginateProducts({
        all_products,
        per_page: state.meta.per_page,
        page: 1,
      });

      return {
        ...state,
        products,
        meta: {
          ...state.meta,
          page: 1,
          total_pages: Math.ceil(all_products.length / state.meta.per_page),
          total_count: all_products.length,
        },
      };
    }
    case SET_PRODUCT_ID: {
      return {
        ...state,
        product_id: payload.product_id,
      };
    }
    case UPDATE_PRODUCT: {
      const all_products = [...state.all_products];
      const products = [...state.products];

      const all_products_index = all_products.indexOf(
        all_products.find(({ id }) => id === payload.product_id)
      );

      const products_index = products.indexOf(
        products.find(({ id }) => id === payload.product_id)
      );
      const product = all_products[all_products_index];
      const product_with_margin_and_markup = calculateProductPriceFields(
        { ...product, ...payload.values },
        state.exchange_rates
      );

      if (all_products_index > -1) {
        all_products[all_products_index] = product_with_margin_and_markup;
      }

      if (products_index > -1) {
        products[products_index] = product_with_margin_and_markup;
      }

      return {
        ...state,
        all_products,
        products,
      };
    }
    case UPDATE_PRODUCT_CUSTOM_PURCHASE_PRICE: {
      const all_products = [...state.all_products];
      const products = [...state.products];

      const all_products_index = all_products.indexOf(
        all_products.find(({ id }) => id === payload.product_id)
      );

      const products_index = products.indexOf(
        products.find(({ id }) => id === payload.product_id)
      );
      const product = all_products[all_products_index];

      const product_with_margin_and_markup = calculateProductPriceFields(
        { ...product, ...payload.data },
        state.exchange_rates
      );

      if (all_products_index > -1) {
        all_products[all_products_index] = product_with_margin_and_markup;
      }

      if (products_index > -1) {
        products[products_index] = product_with_margin_and_markup;
      }

      return {
        ...state,
        all_products,
        products,
      };
    }
    case SET_OPEN_CALCULATOR: {
      return { ...state, is_open_calculator: payload.is_open_calculator };
    }
    default:
      return state;
  }
};

export default mainListReducer;
