import React, { useEffect, useMemo, useState } from "react";
import { Input, Select } from "expano-components";
import { Modal } from "components";

import { useProductsPrices } from "../../context/provider";

const vat_options = [
  { label: "0%", value: 0 },
  { label: "5%", value: 0.05 },
  { label: "8%", value: 0.08 },
  { label: "23%", value: 0.23 },
];

const CalculatorModal = () => {
  const {
    state: { is_open_calculator },
    calculateMarkup,
    calculateMargin,
    calculateSuggestedPrice,
    setOpenCalculator,
  } = useProductsPrices();

  const [price, setPrice] = useState("");
  const [vat, setVat] = useState(0.23);

  const price_brutto = useMemo(() => {
    if (!parseFloat(price)) {
      return 0;
    }
    return (parseFloat(price) + parseFloat(price) * vat).toFixed(2);
  }, [price, vat]);

  const price_data = useMemo(() => {
    const suggested_price_brutto = calculateSuggestedPrice({
      purchase_price: price_brutto,
      exchange_rate: 1,
      currency: "pln",
    });

    const margin = calculateMargin({
      purchase_price: price_brutto,
      sales_price: suggested_price_brutto,
    });

    const markup = calculateMarkup({
      purchase_price: price_brutto,
      sales_price: suggested_price_brutto,
    });
    return {
      suggested_price_brutto,
      margin,
      markup,
    };
  }, [price_brutto]);

  useEffect(() => {
    if (is_open_calculator) {
      document.querySelector(".popup.active input#price")?.focus();
    }
  }, [is_open_calculator]);

  const handleClose = () => {
    setOpenCalculator(false);
    setPrice("");
  };

  if (!is_open_calculator) {
    return null;
  }

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        title: "Kalkulator",
      }}
    >
      <div className="row m-b-15">
        <div className="box-6">
          <Input
            type="number"
            label="Zakup (netto)"
            input={{
              name: "price",
              value: price,
              onChange: ({ target: { value } }) => setPrice(value),
            }}
            readOnly
            meta={{ touched: false, valid: true }}
          />
        </div>
        <div className="box-6">
          <Select
            label="VAT"
            options={vat_options}
            value={vat_options.find(({ value }) => value === vat)}
            handleChange={(value) => setVat(value)}
          />
        </div>
      </div>
      <div className="row m-b-15">
        <div className="box-6">
          <Input
            label="Zakup (brutto)"
            input={{
              name: "price_brutto",
              value: price_brutto,
              disabled: true,
            }}
            readOnly
            meta={{ touched: false, valid: true }}
          />
        </div>
        <div className="box-6">
          <Input
            label="Sprzedaż (brutto)"
            input={{
              name: "suggested_price_brutto",
              value: price_data.suggested_price_brutto,
              style: { border: "1px solid #0abe8c" },
              disabled: true,
            }}
            readOnly
            meta={{ touched: false, valid: true }}
          />
        </div>
      </div>
      <div className="row m-b-15">
        <div className="box-6">
          <Input
            label="Narzut"
            input={{
              name: "markup",
              value: price_data.markup ? `${price_data.markup}%` : "",
              disabled: true,
            }}
            readOnly
            meta={{ touched: false, valid: true }}
          />
        </div>
        <div className="box-6">
          <Input
            label="Marża"
            input={{
              name: "margin",
              value: price_data.margin ? `${price_data.margin}%` : "",
              disabled: true,
            }}
            readOnly
            meta={{ touched: false, valid: true }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CalculatorModal;
