import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import { Input } from "expano-components";

import { Modal } from "components";

import { useProductsPrices } from "../../../../../../context/provider";

const EditCustomPurchasePriceModal = ({
  product_id,
  product_sku,
  product_name,
  erp_purchase_price_netto,
  pristine,
  handleClose,
  handleSubmit,
}) => {
  const [is_saving, setSaving] = useState(false);
  const { updateCustomPurchasePrice } = useProductsPrices();

  const onSubmit = async ({ custom_purchase_price_netto }) => {
    try {
      setSaving(true);
      await updateCustomPurchasePrice(product_id, custom_purchase_price_netto);
      setSaving(false);
      handleClose();
    } catch (error) {
      setSaving(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{
        title: `${product_sku} - ${product_name}`,
      }}
    >
      <div className="row ai-c m-b-15">
        <div className="box-12">
          <p>
            <strong>Cena zakupu (netto) z ERP: {erp_purchase_price_netto} PLN</strong>
          </p>
          <Field
            label="Własna cena zakupu (netto)"
            component={Input}
            name="custom_purchase_price_netto"
            type="number"
            postfix="PLN"
          />
        </div>
      </div>
      <div className="product-prices-submit">
        <button
          type="button"
          disabled={is_saving || pristine}
          onClick={handleSubmit(onSubmit)}
          className="btn btn-sm btn-green"
        >
          <span>{is_saving ? "Proszę czekać..." : "Aktualizuj"}</span>
        </button>
      </div>
    </Modal>
  );
};

const form_name = "product_custom_purchase_price_netto";

export default reduxForm({
  form: form_name,
  enableReinitialize: true,
})(EditCustomPurchasePriceModal);
