import React from "react";
import { useQuery } from "@tanstack/react-query";
import { client } from "utils/api";
import queryString from "query-string";

import { PageLoader } from "components";

const ProductImageRedirect = ({ history, location }) => {
  const { sku } = queryString.parse(location.search);

  useQuery(
    ["sku", sku],
    () => client.get(`/products/search?sku=${sku}`),
    {
      select: (res) => res.data,
      retry: false,
      onSuccess: ({ product_id, product_group_id }) => {
        history.push(
          `/product_groups/${product_group_id}/images/products/${product_id}`
        );
      },
      onError: () => {
        history.push("/products");
      },
    }
  );
  return <PageLoader />;
};

export default ProductImageRedirect;
