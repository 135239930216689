import React from "react";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import { ReactComponent as SettingsIcon } from "icons/settings.svg";
import { ReactComponent as ChartIcon } from "icons/chart.svg";

const getNav = ({ permission_display_purchase_price }) => [
  {
    name: "Katalog",
    type: "group",
    icon: <ProductIcon />,
    items: [
      { name: "Produkty", path: "/products" },
      {
        name: "Kategorie",
        path: "/platforms/platform_taxons",
      },
      { name: "Właściwości", path: "/properties" },
      { name: "Prototypy", path: "/prototypes" },
    ],
  },
  permission_display_purchase_price && {
    name: "Analiza",
    type: "single",
    icon: <ChartIcon />,
    items: [
      {
        name: "Analiza",
        path: "/analyze",
      },
    ],
  },
  {
    name: "Zarządzanie",
    type: "group",
    icon: <SettingsIcon />,
    items: [
      {
        name: "Ustawienia sprzedaży",
        path: "/settings",
      },
    ],
  },
];

export default getNav;
