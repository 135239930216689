import React, { useState } from "react";
import LazyLoad from "react-lazy-load";
import { Label, ModalPreview } from "expano-components";
import getProductStatus from "utils/getProductStatus";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { useProductsPrices } from "../../../../context/provider";
import EditPricesModal from "./components/EditPricesModal";
import EditCustomPurchasePriceModal from "./components/EditCustomPurchasePriceModal";

const checkPriceStatus = ({ price, suggested_price }) => {
  if (!price || parseFloat(price) === 0) {
    return "";
  }

  const percent = ((suggested_price - price) / suggested_price) * 100;
  if (percent > 0 && percent <= 5) {
    return "cel-warning";
  }
  if (percent > 5) {
    return "cel-danger";
  }
  return "";
};

const TableRows = () => {
  const [preview_image, setPreviewImage] = useState(null);
  const [edit_purchase_price_data, setEditPurchasePriceData] = useState(null);
  const {
    state: { product_id, products, exchange_rates },
    setProductId,
    setFilterValue,
  } = useProductsPrices();

  const getCurrencyRate = (currency) =>
    exchange_rates.find((item) => item.currency === currency)?.rate || 0;

  return (
    <>
      {preview_image && (
        <ModalPreview
          src={preview_image}
          handleClose={() => setPreviewImage(null)}
        />
      )}
      {edit_purchase_price_data?.product_id && (
        <EditCustomPurchasePriceModal
          {...edit_purchase_price_data}
          initialValues={{
            custom_purchase_price_netto:
              edit_purchase_price_data.custom_purchase_price_netto
          }}
          handleClose={() => setEditPurchasePriceData(null)}
        />
      )}
      <tbody>
        {products?.map(
          ({
            id,
            expano_root_id,
            expano_wms_id,
            sku,
            name,
            status,
            product_group,
            producer,
            product_supplier,
            producer_code,
            ean,
            global_image_url,
            global_image_thumb_url,
            purchase_price_brutto_value,
            purchase_price_brutto_value_eur,
            purchase_price_brutto_value_czk,
            purchase_price_brutto_value_sek,
            purchase_price_brutto_value_gbp,
            purchase_price_brutto_value_huf,
            sales_price_pln,
            sales_price_eur,
            sales_price_czk,
            sales_price_sek,
            sales_price_gbp,
            sales_price_huf,
            sales_price_pln_markup,
            sales_price_eur_markup,
            sales_price_czk_markup,
            sales_price_sek_markup,
            sales_price_gbp_markup,
            sales_price_huf_markup,
            sales_price_pln_margin,
            sales_price_eur_margin,
            sales_price_czk_margin,
            sales_price_sek_margin,
            sales_price_gbp_margin,
            sales_price_huf_margin,
            suggested_sale_price_pln,
            suggested_sale_price_eur,
            suggested_sale_price_czk,
            suggested_sale_price_sek,
            suggested_sale_price_gbp,
            suggested_sale_price_huf,
            custom_purchase_price_netto,
            erp_purchase_price_netto,
            purchase_price_netto_value,
            is_bundle,
            is_virtual,
          }) => {
            const vat_rate =
              product_group?.vat_rates?.length > 0
                ? product_group.vat_rates
                    .filter(({ country_code }) => country_code === "PL")
                    .map(({ value }) => value)
                : 23;

            return (
              <tr key={id}>
                {product_id === id && (
                  <EditPricesModal
                    purchase_price_netto_value={purchase_price_netto_value}
                    vat_rate={vat_rate}
                    purchase_price_brutto_value={purchase_price_brutto_value}
                    purchase_price_brutto_value_eur={
                      purchase_price_brutto_value_eur
                    }
                    purchase_price_brutto_value_czk={
                      purchase_price_brutto_value_czk
                    }
                    purchase_price_brutto_value_sek={
                      purchase_price_brutto_value_sek
                    }
                    purchase_price_brutto_value_gbp={
                      purchase_price_brutto_value_gbp
                    }
                    purchase_price_brutto_value_huf={
                      purchase_price_brutto_value_huf
                    }
                    suggested_sale_price_pln={suggested_sale_price_pln}
                    suggested_sale_price_eur={suggested_sale_price_eur}
                    suggested_sale_price_czk={suggested_sale_price_czk}
                    suggested_sale_price_sek={suggested_sale_price_sek}
                    suggested_sale_price_gbp={suggested_sale_price_gbp}
                    suggested_sale_price_huf={suggested_sale_price_huf}
                    product_id={id}
                    product_sku={sku}
                    product_name={name}
                    initialValues={{
                      sales_price_pln,
                      sales_price_eur,
                      sales_price_czk,
                      sales_price_sek,
                      sales_price_gbp,
                      sales_price_huf,
                      do_export_channel_products_prices: true,
                    }}
                  />
                )}
                <td>
                  <div className="row ai-c">
                    {global_image_thumb_url ? (
                      <LazyLoad height={50} width={50}>
                        <button
                          type="button"
                          className="btn-preview"
                          onClick={() => setPreviewImage(global_image_url)}
                          style={{
                            backgroundImage: `url(${global_image_thumb_url})`,
                          }}
                        />
                      </LazyLoad>
                    ) : (
                      <div className="btn-preview-blank" />
                    )}
                    <div className="p-l-10">
                      <p>
                        <strong>{sku}</strong>{" "}
                        {product_group?.sku && (
                          <>
                            (
                            <span
                              className="text-muted text-hoverable"
                              onClick={() =>
                                setFilterValue({
                                  name: "name_sku_or_product_group_sku",
                                  value: is_virtual
                                    ? "is:virtual"
                                    : is_bundle
                                    ? "is:bundle"
                                    : "is:unit",
                                  label: is_virtual
                                    ? "Virtual"
                                    : is_bundle
                                    ? "Bundle"
                                    : "Unit",
                                })
                              }
                            >
                              {is_virtual ? "V" : is_bundle ? "B" : "U"}
                            </span>
                            ,{" "}
                            <span
                              className="text-muted text-hoverable"
                              onClick={() =>
                                setFilterValue({
                                  name: "name_sku_or_product_group_sku",
                                  value: product_group.sku,
                                  label: product_group.sku,
                                })
                              }
                            >
                              {product_group.sku}
                            </span>
                            )
                          </>
                        )}
                      </p>
                      <p style={{ maxWidth: 500 }}>{name}</p>
                      <div className="labels-group">
                        <a
                          href={`https://root.tradesk.pl/products/${expano_root_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Label type="bordered" color="#fd397a" text="ROOT" />
                        </a>
                        <a
                          href={`/product_groups/${product_group.id}/price/products/${id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Label type="bordered" color="#36bcff" text="PIM" />
                        </a>
                        <a
                          href={`https://wms.tradesk.pl/products/${expano_wms_id}/basic`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Label type="bordered" color="#ae22ff" text="WMS" />
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {producer?.name && (
                    <p>
                      <span
                        className="text-hoverable"
                        onClick={() =>
                          setFilterValue({
                            name: "producer_name",
                            value: producer.name,
                            label: producer.name,
                          })
                        }
                      >
                        {producer.name}
                      </span>
                    </p>
                  )}
                  <p className="text-muted">{producer_code}</p>
                  <p className="text-muted">{ean}</p>
                </td>
                <td>
                  {product_supplier?.supplier_name && (
                    <p>
                      <span
                        className="text-hoverable"
                        onClick={() =>
                          setFilterValue({
                            name: "supplier_name",
                            value: product_supplier.supplier_name,
                            label: product_supplier.supplier_name,
                          })
                        }
                      >
                        {product_supplier.supplier_name}
                      </span>
                    </p>
                  )}
                  {product_supplier?.supplier_code && (
                    <p className="text-muted">
                      {product_supplier.supplier_code}
                    </p>
                  )}
                </td>
                <td>
                  <div
                    className={`product_status ${
                      getProductStatus(status).color
                    }`}
                  >
                    <span>{getProductStatus(status).name}</span>
                  </div>
                </td>
                <td>
                  {is_bundle ? (
                    <>
                      {purchase_price_netto_value ? (
                        <span>
                          {parseFloat(purchase_price_netto_value).toFixed(2)}{" "}
                          PLN
                        </span>
                      ) : (
                        "-"
                      )}
                    </>
                  ) : (
                    <>
                      <span
                        className={!is_virtual ? "text-hoverable" : ""}
                        onClick={() =>
                          !is_virtual &&
                          setEditPurchasePriceData({
                            product_id: id,
                            product_sku: sku,
                            product_name: name,
                            custom_purchase_price_netto,
                            erp_purchase_price_netto,
                          })
                        }
                        style={
                          purchase_price_netto_value !==
                          erp_purchase_price_netto
                            ? { textDecoration: "line-through" }
                            : {}
                        }
                      >
                        {!!parseFloat(erp_purchase_price_netto)
                          ? `${parseFloat(erp_purchase_price_netto).toFixed(
                              2
                            )} PLN`
                          : "-"}
                      </span>
                      {purchase_price_netto_value !==
                        erp_purchase_price_netto &&
                        purchase_price_netto_value ===
                          custom_purchase_price_netto && (
                          <span style={{ marginLeft: 5 }}>
                            {!!parseFloat(custom_purchase_price_netto)
                              ? `${parseFloat(
                                  custom_purchase_price_netto
                                ).toFixed(2)} PLN`
                              : ""}
                          </span>
                        )}
                    </>
                  )}
                </td>
                <td>
                  {purchase_price_brutto_value ? (
                    <span>
                      {parseFloat(purchase_price_brutto_value).toFixed(2)} PLN
                    </span>
                  ) : (
                    "-"
                  )}
                  <p className="text-muted">vat: {vat_rate}%</p>
                </td>
                <td
                  className={checkPriceStatus({
                    price: sales_price_pln,
                    suggested_price: suggested_sale_price_pln,
                  })}
                >
                  {sales_price_pln ? (
                    <>
                      {parseFloat(sales_price_pln).toFixed(2)} PLN
                      <p className="text-muted">
                        narzut: {sales_price_pln_markup}%
                      </p>
                      <p className="text-muted">
                        marża: {sales_price_pln_margin}%
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  className={checkPriceStatus({
                    price: sales_price_eur,
                    suggested_price: suggested_sale_price_eur,
                  })}
                >
                  {sales_price_eur > 0 ? (
                    <>
                      {parseFloat(sales_price_eur).toFixed(2)} EUR
                      <p className="text-muted">
                        ≈{" "}
                        {(sales_price_eur * getCurrencyRate("eur")).toFixed(2)}{" "}
                        PLN
                      </p>
                      <p className="text-muted">
                        narzut: {sales_price_eur_markup}%
                      </p>
                      <p className="text-muted">
                        marża: {sales_price_eur_margin}%
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  className={checkPriceStatus({
                    price: sales_price_czk,
                    suggested_price: suggested_sale_price_czk,
                  })}
                >
                  {sales_price_czk > 0 ? (
                    <>
                      {parseFloat(sales_price_czk).toFixed(2)} CZK
                      <p className="text-muted">
                        ≈{" "}
                        {(sales_price_czk * getCurrencyRate("czk")).toFixed(2)}{" "}
                        PLN
                      </p>
                      <p className="text-muted">
                        narzut: {sales_price_czk_markup}%
                      </p>
                      <p className="text-muted">
                        marża: {sales_price_czk_margin}%
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  className={checkPriceStatus({
                    price: sales_price_sek,
                    suggested_price: suggested_sale_price_sek,
                  })}
                >
                  {sales_price_sek > 0 ? (
                    <>
                      {parseFloat(sales_price_sek).toFixed(2)} SEK
                      <p className="text-muted">
                        ≈{" "}
                        {(sales_price_sek * getCurrencyRate("sek")).toFixed(2)}{" "}
                        PLN
                      </p>
                      <p className="text-muted">
                        narzut: {sales_price_sek_markup}%
                      </p>
                      <p className="text-muted">
                        marża: {sales_price_sek_margin}%
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  className={checkPriceStatus({
                    price: sales_price_gbp,
                    suggested_price: suggested_sale_price_gbp,
                  })}
                >
                  {sales_price_gbp > 0 ? (
                    <>
                      {parseFloat(sales_price_gbp).toFixed(2)} GBP
                      <p className="text-muted">
                        ≈{" "}
                        {(sales_price_gbp * getCurrencyRate("gbp")).toFixed(2)}{" "}
                        PLN
                      </p>
                      <p className="text-muted">
                        narzut: {sales_price_gbp_markup}%
                      </p>
                      <p className="text-muted">
                        marża: {sales_price_gbp_margin}%
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  className={checkPriceStatus({
                    price: sales_price_huf,
                    suggested_price: suggested_sale_price_huf,
                  })}
                >
                  {sales_price_huf > 0 ? (
                    <>
                      {parseFloat(sales_price_huf).toFixed(2)} HUF
                      <p className="text-muted">
                        ≈{" "}
                        {(sales_price_huf * getCurrencyRate("huf")).toFixed(2)}{" "}
                        PLN
                      </p>
                      <p className="text-muted">
                        narzut: {sales_price_huf_markup}%
                      </p>
                      <p className="text-muted">
                        marża: {sales_price_huf_margin}%
                      </p>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn-icon"
                    onMouseDown={() => setProductId(id)}
                  >
                    <EditIcon />
                  </button>
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </>
  );
};

export default TableRows;
